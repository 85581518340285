import { ApolloClient } from 'apollo-client';
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import fetch from 'node-fetch';

// import { InMemoryCache, NormalizedCacheObject } from '@apollo/client/core';
// import { persistCache } from 'apollo3-cache-persist';

import { isBrowser, dev } from '@lib/helpers';
import { useConfig } from '@server/lib/config';
import _get from 'lodash/get';

const ENV_CONFIG = useConfig();

let apolloClient = null;

/**
 * Always creates a new apollo client on the server
 * Creates or reuses apollo client in the browser.
 * @param  {NormalizedCacheObject} initialState
 */
export function initApolloClient(
  initialState: NormalizedCacheObject = {}
): ApolloClient<NormalizedCacheObject> {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (typeof window === 'undefined') {
    apolloClient = createApolloClient(initialState);
    // return createApolloClient(initialState);
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = createApolloClient(initialState);
  }

  return apolloClient;
}

interface IHttpLinkProps {
  uri: string;
  fetch: any;
  credentials: string;
}

const GRAPHQL_URL =
  _get(process, 'env.GRAPHQL_URL') ||
  _get(process, 'env.NEXT_PUBLIC_GRAPHQL_URL') ||
  _get(ENV_CONFIG, 'GRAPHQL_URL');
const PORT = _get(process, 'env.PORT', 4100);

const URI = dev
  ? _get(ENV_CONFIG, 'GRAPHQL_URL_DEV') || `http://localhost:${PORT}/graphql`
  : GRAPHQL_URL ||
    (isBrowser
      ? `${window.location.origin}/graphql`
      : `http://localhost:${PORT}/graphql`);

const httpLinkParams: IHttpLinkProps = {
  uri: URI,
  fetch: fetch,
  credentials: 'same-origin', // same domain, use credentials: 'include' if graphql is different domain
};
const httpLink = new HttpLink(httpLinkParams);
const errorLink = onError(({ graphQLErrors, networkError, ...other }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) =>
      console.error(
        `🔥[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(
          locations
        )}, Path: ${path}`
      )
    );
  }

  if (networkError) {
    console.error(`🔥[Network error]: ${networkError}`);
    console.error(other);
  }
});
const link = ApolloLink.from([errorLink, httpLink]);

/**
 * Creates and configures the ApolloClient
 * @param  {NormalizedCacheObject} [initialState={}]
 */
export const createApolloClient = (
  initialState: NormalizedCacheObject = {}
): ApolloClient<NormalizedCacheObject> => {
  const cache = new InMemoryCache();
  // Check out https://github.com/zeit/next.js/pull/4611 if you want to use the AWSAppSyncClient

  const client = new ApolloClient({
    ssrMode: typeof window === 'undefined', // Disables forceFetch on the server (so queries are only run once)
    link,
    cache: cache.restore(initialState),
    // If you want to use Apollo Client's @client support to query the cache without using local resolvers,
    // you must pass an empty object into the ApolloClient constructor resolvers option
    resolvers: {},
    connectToDevTools: true,
  });

  // cache.writeData({
  //   data: defaultClientState
  // });

  return client;
};
